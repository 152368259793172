import React from "react"
import { Col } from "reactstrap"

const TestimonialCard = ({
  author,
  authorSubtext,
  avatarSrc,
  children,
  introText,
}) => (
  <Col
    lg={{ size: 8, offset: 2 }}
    md={{ size: 10, offset: 1 }}
    className="Card Card-white"
    style={{ textAlign: "left" }}
  >
    {introText ? (
      <h4 className="u-marginBottom40">
        <mark>{introText}</mark>
      </h4>
    ) : null}

    <div>{children}</div>
    <div style={{ textAlign: "center", marginTop: "40px" }}>
      <img
        src={avatarSrc}
        alt={author}
        className="u-circle"
        style={{
          width: "70px",
          margin: "20px auto",
        }}
      />
      <h5>{author}</h5>
      <h5 className="subtext">{authorSubtext}</h5>
    </div>
  </Col>
)

export default TestimonialCard

import React from "react"

const SVGChartGradient = ({ id, startColor, stopColor }) => (
  <div style={{ position: "absolute", zIndex: -1 }}>
    <svg style={{ width: "200" }}>
      <defs>
        <linearGradient id={id} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor={startColor} />
          <stop offset="100%" stopColor={stopColor} />
        </linearGradient>
      </defs>
    </svg>
  </div>
)

export default SVGChartGradient

import React, { Fragment } from "react"
import { BarChart, Bar, XAxis } from "recharts"
import CountUp from "react-countup"
import Fade from "react-reveal/Fade"

import { easeOutQuad } from "../../utilities/easing"

import Heading from "../../components/index/Heading"
import SVGChartGradient from "../../components/charts/SVGChartGradient"

class GenericAutomationChartContainer extends React.Component {
  state = {
    gradientId: "orangeRedGradient",
    isRevealed: false,
  }

  setData = () => {
    this.setState({ isRevealed: true })
  }

  render() {
    // Given the chart data, find the y value for Carebit so we can use it
    // in the counter to show the final value (whatever it might be) for Carebit.
    let carebitEndValue = this.props.chartData.find(obj => obj.x === "Carebit")
      .y

    return (
      <Fade delay={1000} onReveal={this.setData}>
        {this.state.isRevealed ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <SVGChartGradient
              id={this.state.gradientId}
              startColor="#f12711"
              stopColor="#f5af19"
            />

            <div className="Card" style={{ margin: "0 auto" }}>
              <Heading title="Data">
                {this.props.countUpTextBefore ? (
                  <Fragment>
                    {this.props.countUpTextBefore}
                    <CountUp
                      easingFn={easeOutQuad}
                      start={1}
                      end={carebitEndValue}
                      delay={0.9}
                      duration={2.9}
                    />
                    {this.props.countUpTextAfter}
                  </Fragment>
                ) : (
                  this.props.staticText
                )}
              </Heading>

              <BarChart
                width={200}
                height={400}
                data={this.props.chartData}
                barSize={50}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis stroke="#8fa1b3" dataKey="x" />
                <Bar
                  dataKey="y"
                  animationDuration={3000}
                  fill="url(#orangeRedGradient)"
                />
              </BarChart>
            </div>
          </div>
        ) : (
          <div style={{ width: 200, height: 400 }} />
        )}
      </Fade>
    )
  }
}

export default GenericAutomationChartContainer
